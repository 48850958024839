<template>
  <div>
    <v-container fluid class="pa-0">
      <v-row align="center" justify="center" class="secondary pt-12">
        <v-col
          cols="12"
          md="10"
          lg="8"
          class="py-12 d-flex justify-center align-center flex-column"
        >
          <v-img height="30vh" src="/privacy.svg" contain class="my-12"></v-img>
          <h1 class="white--text pt-12 text-center">
            Aviso de privacidad integral
          </h1>
          <h5 class="white--text mt-3 pb-12 font-weight-regular">
            <strong>Última actualización:</strong> 30 de noviembre de 2021.
          </h5>
        </v-col>
      </v-row>
      <v-row class="pt-12 mx-0" align="center" justify="center">
        <v-col cols="12" md="10" lg="7" xl="5">
          <h3 class="secondary--text mb-2">Proveedores</h3>
          <p>
            GRUPO EMPRESARIAL TIDINGO S.A DE C.V, es el responsable del uso y
            protección de sus datos personales común mente conocido por su
            nombre comercial “GRUPO EMPRESARIAL TIDINGO”, y al respecto le
            informamos lo siguiente:
          </p>
          <p>
            Los datos personales se refieren a la información concerniente a una
            persona física identificada o identificable, y por datos personales
            sensibles, aquellos que afecten a la esfera más íntima de su titular
            o cuya utilización indebida pueda dar origen a discriminación o
            conlleve un riesgo grave para éste.
          </p>
        </v-col>
        <v-col cols="12" md="10" lg="7" xl="5">
          <h3 class="secondary--text mb-2">El domicilio del responsable</h3>
          <p>
            Con domicilio en Calle Libramiento Carretero #1050, Colonia Nuevas
            Palomas, Tepic, Nayarit, México C.P 63195, con datos de contacto
            para el público en general: soporte@gremti.com, número de teléfono
            (332) 832 68 70 y página de internet: www.gremti.com.
          </p>
        </v-col>
        <v-col cols="12" md="10" lg="7" xl="5">
          <h3 class="secondary--text mb-2">
            ¿Qué datos personales utilizaremos?
          </h3>
          <p>
            Para llevar a cabo las finalidades descritas en el presente aviso de
            privacidad, utilizaremos los siguientes datos personales:
          </p>
          <ol>
            <li class="font-weight-bold">Ordinarios</li>
            <ul>
              <li class="font-weight-bold">Identificativos:</li>
              <ul>
                <li>El nombre completo</li>
                <li>Nacionalidad</li>
                <li>Fotografía</li>
                <li>Firma autógrafa</li>
                <li>Número de INE o IFE</li>
                <li>Firma autógrafa</li>
                <li>Registro Federal de Contribuyentes (RFC)</li>
                <li>Clave Única de Registro Poblacional (CURP)</li>
                <li>Geolocalización</li>
              </ul>
              <li class="font-weight-bold">Laborales:</li>
              <ul>
                <li>Domicilio fiscal</li>
                <li>Correo electrónico</li>
                <li>Número telefónico</li>
                <li>Giro comercial</li>
              </ul>

              <li class="font-weight-bold">Patrimoniales:</li>
              <ul>
                <li>Denominación o Razón Social</li>
                <li>Número de acta constitutiva</li>
                <li>Datos bancarios</li>
              </ul>
            </ul>
            <li class="font-weight-bold">Sensibles</li>
            <ul>
              <li>No se recaban datos sensibles</li>
            </ul>
          </ol>
        </v-col>
        <v-col cols="12" md="10" lg="7" xl="5">
          <h3 class="secondary--text mb-2">
            Mecanismos por los cuáles se recaban datos personales.
          </h3>
          <p>
            Los datos personales enlistados los reclamos directamente del
            titular, de forma personal y por medios remotos o locales de
            comunicación electrónica denominada correo electrónico que permita
            recabar datos personales de manera automática y simultánea al tiempo
            que el titular hace contacto con los mismos, así como de forma
            presencial.
          </p>
        </v-col>
        <v-col cols="12" md="10" lg="7" xl="5">
          <h3 class="secondary--text mb-2">
            ¿PARA QUÉ FINES UTILIZAREMOS SUS DATOS PERSONALES?
          </h3>
          <p>
            Los datos personales que recabamos de usted, los utilizaremos para
            las siguientes finalidades que son necesarias para el servicio que
            solicita:
          </p>
          <ul>
            <li><p>Registro de clientes y proveedores.</p></li>
            <li>
              <p>
                Atender los requerimientos de compras de las Unidades de Negocio
                validando sus solicitudes con los convenios y contratos
                preestablecidos.
              </p>
            </li>
            <li><p>Solicitar cotización a los proveedores.</p></li>
            <li>
              <p>Generar órdenes de compras y asegurar su validación.</p>
            </li>
            <li>
              <p>
                Confirmar el envío recepción y asegurar la provisión de las
                órdenes de compra.
              </p>
            </li>
            <li><p>Cumplir con los contratos y convenios.</p></li>
          </ul>
          <p>
            Sus datos personales no son utilizados para
            <strong>fines distintos</strong> a los señalados en el presente
            aviso de privacidad, ni
            <strong>finalidades secundarias o accesorias</strong> para las
            cuáles se necesita su consentimiento expreso.
          </p>
        </v-col>
        <v-col cols="12" md="10" lg="7" xl="5">
          <h3 class="secondary--text mb-2">
            ¿Con quién compartimos su información personal y para qué fines?
          </h3>
          <p>
            Le informamos que sus datos personales
            <strong> NO serán compartidos </strong>dentro o fuera del país con
            personas, empresas, organizaciones o autoridades distintas a
            nosotros con finalidades de transferencia secundarias, únicamente
            los necesarios para efectos de fiscalización con las autoridades
            competentes, o para las autoridades administrativas,
            jurisdiccionales en el ejercicio de sus funciones y atribuciones,
            para los cuáles
            <strong> no es necesario su consentimiento </strong>ya que se
            encuentra previsto en la normatividad como excepción.
          </p>
          <p>
            Se le informa que no se consideran transferencias las remisiones ni
            la comunicación de datos entre áreas o unidades administrativas
            adscritas a esta Institución.
          </p>
        </v-col>
        <v-col cols="12" md="10" lg="7" xl="5">
          <h3 class="secondary--text mb-2">
            ¿Cómo puede acceder, portar, rectificar o cancelar sus datos
            personales, u oponerse a su uso?
          </h3>
          <p>Usted tiene derecho a lo siguiente:</p>
          <ul>
            <li class="font-weight-bold">Acceder</li>
            <p>
              Conocer qué datos personales tenemos de usted, para qué los
              utilizamos y las condiciones del uso que les damos;
            </p>
            <li class="font-weight-bold">Rectificar</li>
            <p>
              Solicitar la corrección de su información personal en caso de que
              esté desactualizada, sea inexacta o incompleta;
            </p>
            <li class="font-weight-bold">Cancelar</li>
            <p>
              Que la eliminemos de nuestros registros o bases de datos cuando
              considere que la misma no está siendo utilizada adecuadamente;
            </p>
            <li class="font-weight-bold">Oponerse</li>
            <p>Al uso de sus datos personales para fines específicos.</p>
          </ul>
          <p>
            A los derechos citados se les conocen como derechos
            <strong>ARCO.</strong>
          </p>
          <p>
            Para el ejercicio de cualquiera de los derechos
            <strong>ARCO</strong>, usted deberá presentar solicitud en nuestras
            oficinas, con domicilio en Calle Libramiento Carretero #1050,
            Colonia Nuevas Palomas, Tepic, Nayarit, México C.P 63195, con datos
            de contacto para el público en general, o bien puede presentarla de
            manera electrónica al correo general: soporte@gremti.com.
          </p>
          <p>
            Los requisitos para presentar su solicitud de derechos
            <strong>ARCO</strong> son los siguientes:
          </p>
          <ol type="I">
            <li>
              De ser posible, el área responsable que trata los datos personales
              y ante el cual se presenta la solicitud;
            </li>
            <li>
              Nombre del solicitante titular de la información y del
              representante, en su caso;
            </li>
            <li>
              Domicilio o cualquier otro medio para recibir notificaciones;
            </li>
            <li>
              Los documentos con los que acredite su identidad y, en su caso, la
              personalidad e identidad de su representante;
            </li>
            <li>
              La descripción del derecho ARCO que se pretende ejercer, o bien,
              lo que solicita el titular;
            </li>
            <li>
              Descripción clara y precisa de los datos sobre los que se busca
              ejercer alguno de los derechos ARCO, salvo que se trate del
              derecho de acceso; y
            </li>
            <li>
              Cualquier otro elemento o documento que facilite la localización
              de los datos personales, en su caso.
            </li>
          </ol>
          <p>
            Para conocer el procedimiento para el ejercicio de los derechos
            <strong>ARCO</strong>, puede acudir nuestras oficinas en el
            Departamento Jurídico con domicilio en Calle Libramiento Carretero
            #1050, Colonia Nuevas Palomas, Tepic, Nayarit, México C.P 63195, o
            bien puede presentarla de manera electrónica al correo:
            soporte@gremti.com.
          </p>
          <p>
            El derecho a la <strong>Portabilidad</strong> de sus datos
            personales no es posible, al no ser técnicamente viable por no
            tratarse en medios automatizados, en formato estructurado, de uso
            común, de lectura mecánica e interoperable.
          </p>
        </v-col>
        <v-col cols="12" md="10" lg="7" xl="5">
          <h3 class="secondary--text mb-2">
            ¿Cómo puede revocar su consentimiento para el uso de sus datos
            personales?
          </h3>
          <p>
            Usted puede revocar el consentimiento que, en su caso, nos haya
            otorgado para el tratamiento de sus datos personales. Sin embargo,
            es importante que tenga en cuenta que no en todos los casos podremos
            atender su solicitud o concluir el uso de forma inmediata, ya que es
            posible que por alguna obligación legal requiramos seguir tratando
            sus datos personales. Asimismo, usted deberá considerar que para los
            fines señalados en el presente Aviso de Privacidad,
            <strong>
              la revocación de su consentimiento implicará que no le podamos
              seguir prestando el servicio que nos solicitó
            </strong>
            , o la conclusión de su relación con nosotros.
          </p>
          <p>
            Para revocar su consentimiento deberá presentar su solicitud en el
            Departamento Jurídico con domicilio en Calle Libramiento Carretero
            #1050, Colonia Nuevas Palomas, Tepic, Nayarit, México C.P 63195.
          </p>
          <p>
            Para conocer el procedimiento y requisitos para la revocación del
            consentimiento, usted podrá llamar al siguiente número telefónico
            (332) 832 68 70; o bien ponerse en contacto con nuestro Departamento
            de Jurídico al siguiente correo electrónico:
            <a href="mailto:soporte@gremti.com">soporte@gremti.com</a>.
          </p>
        </v-col>
        <v-col cols="12" md="10" lg="7" xl="5">
          <h3 class="secondary--text mb-2">
            ¿Cómo puede limitar el uso o divulgación de su información personal?
          </h3>
          <p>
            Con objeto de que usted pueda limitar el uso y divulgación de su
            información personal, le ofrecemos los siguientes medios:
          </p>
          <ul>
            <li>
              Su inscripción en el Registro Público para Evitar Publicidad, que
              está a cargo de la Procuraduría Federal del Consumidor, con la
              finalidad de que sus datos personales no sean utilizados para
              recibir publicidad o promociones de empresas de bienes o
              servicios. Para mayor información sobre este registro, usted puede
              consultar el portal de Internet de la PROFECO, o bien ponerse en
              contacto directo con ésta.
            </li>
            <li>
              Su registro en el listado de exclusión, a fin de que sus datos
              personales no sean tratados para fines mercadotécnicos,
              publicitarios o de prospección comercial por nuestra parte. Para
              mayor información llamar al número telefónico (332) 832 68 70,
              enviar un correo electrónico a la siguiente dirección
              soporte@gremti.com.
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="10" lg="7" xl="5">
          <h3 class="secondary--text mb-2">
            El uso de tecnologías de rastreo en nuestro portal de internet
          </h3>
          <p>No Utilizamos Cookies y Web beacons para el proceso de compras.</p>
        </v-col>
        <v-col cols="12" md="10" lg="7" xl="5">
          <h3 class="secondary--text mb-2">
            ¿Cómo puede conocer los cambios en este aviso de privacidad?
          </h3>
          <p>
            El presente aviso de privacidad puede sufrir modificaciones, cambios
            o actualizaciones derivadas de nuevos requerimientos legales o de
            nuestras propias necesidades. Nos comprometemos a mantenerlo
            informado sobre los cambios que pueda sufrir el presente aviso de
            privacidad, a través de www.gremti.com.
          </p>
          <p>
            El procedimiento a través del cual se llevarán a cabo las
            notificaciones sobre cambios o actualizaciones al presente aviso de
            privacidad es el siguiente:
          </p>
          <p>Se publicará en el sitio de internet: www.gremti.com.</p>
        </v-col>
        <v-col cols="12" md="10" lg="7" xl="5">
          <h3 class="secondary--text mb-2">Información sobre el INAI</h3>
          <p>
            Si usted considera que su derecho a la protección de sus datos
            personales ha sido lesionado por alguna conducta u omisión de
            nuestra parte, o presume alguna violación a las disposiciones
            previstas en la Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares, su Reglamento y demás ordenamientos
            aplicables, podrá interponer su inconformidad o denuncia ante el
            Instituto Nacional de Transparencia, Acceso a la Información y
            Protección de Datos Personales (INAI). Para mayor información, le
            sugerimos visitar su página oficial de Internet www.inai.org.mx.
          </p>
        </v-col>
        <v-col cols="12" md="10" lg="7" xl="5">
          <p class="text-right">
            <strong>Última actualización:</strong> 30 de noviembre de 2021.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style scoped>
p,
li {
  line-height: 1.9rem;
  font-size: 1.1rem;
  text-align: justify;
}
</style>

<script>
const AvisoApp = () =>
  import(/* webpackChunkName: "headerimage" */ "../components/AvisoApp.vue");

export default {
  components: {
    AvisoApp,
  },
  data: () => ({}),
};
</script>
